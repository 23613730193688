import playstore from '../../assets/images/playstore.webp'
import appstore from '../../assets/images/appstore.webp'
import firstimg from '../../assets/images/bannerimage1.webp'
import secondimg from '../../assets/images/bannerimage2.webp'
import bgvector from '../../assets/images/bannerbg.webp'
import ellips from '../../assets/images/circle.webp'
import upellips from '../../assets/images/176.webp'
import bottomellips from '../../assets/images/177.webp'
import serviceellips from '../../assets/images/76.webp'
import logo from '../../assets/images/logo.webp'
import logolight from '../../assets/images/logolight.webp'
import user1 from '../../assets/images/user1.webp'
import user2 from '../../assets/images/user2.webp'
import user3 from '../../assets/images/user3.webp'
import passChanged from '../../assets/images/passChanged.svg'
import email404 from '../../assets/images/email404.svg'
import slide1 from '../../assets/images/slide1.webp'
import slide2 from '../../assets/images/slide2.webp'
import slide3 from '../../assets/images/slide3.webp'
import slide4 from '../../assets/images/slide4.webp'


export  default{
    playstore : playstore,
    appstore : appstore,
    firstimg : firstimg,
    secondimg : secondimg,
    bgvector :bgvector ,
    ellips : ellips,
    upellips : upellips,
    bottomellips : bottomellips,
    serviceellips : serviceellips,
    logo :logo ,
    logolight:logolight,
    user1 : user1,
    user2 :user2 ,
    user3 :user3 ,
    passChanged :passChanged ,
    email404 :email404 ,
    slide1 :slide1 ,
    slide2 :slide2 ,
    slide3 : slide3,
    slide4 :slide4 ,
};