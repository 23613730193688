
import images from '../../utils/theme/images';

import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { forgotpasswordapi } from "../../utils/api/ApiAction";
import { Modal, message } from 'antd';
import FModel from "../../components/model";

function Forgotpassword() { 
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const navigateTo = () => navigate("/otp"); //eg.history.push('/login');

  useEffect(() => {
    console.log("email", email);
  }, [email]);

  const loader =(isload)=>{
    var element = document.getElementById("loader-body");
    if(isload){
      element.classList.remove("d-none");
    }else{
      element.classList.add("d-none");
    }
  }

  const messageAlert =(msg,isError)=>{
    message.open({
      type:  isError ? 'error' : 'success',
      content: msg,
      className: 'custom-class',
      duration: 2, 
      style: {
        marginTop: '120px',
      },
    });
  }
  const forgotpasswordAPICALL = async () => {
    
    setError("");
    if (email === "") {
      return;
    }

    loader(true);
    let payload = { username: email };
    
    let response = await forgotpasswordapi(payload)
    console.log("abcd",response)
    if (response.status === 200) {
      messageAlert("login successfully...",false);
      navigateTo();
    } else {
     
      // message.error(response?.data?.message ?? "Something wrong")
      messageAlert(response?.data?.message ?? "Something wrong",true);
      setError(response?.data?.message ?? "Something wrong");
      setOpen(true)
    }
    loader(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  
  return (
    <div className="login forgotPassWord">

    <FModel isOpen={open} setOpen={setOpen} />
    
      <div className="container">
        <div className="row align-items-center login-page-content">
          <div className="col-md-6">
            <img src={images.logo} />
            <h2 className="text-start m-0">
            Forgot password
            </h2>
            <p className="m-0 login-text">
            If you have forgotten your password, there's no need to worry as we've got you covered. We send password reset instruction on your email.
            </p>
            <div className="user-joined">
              <div className="image-group">
                <img src={images.user1} />
                <img src={images.user2} />
                <img src={images.user3} />
              </div>
              <p className="m-0 p-0">
                3k+ people joined us, now it’s your turn
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="log-in-form position-relative step-one ">
              <div className="position-absolute loader-body d-none" id="loader-body">
                <div class="loader-circle-2 ">
                  
                </div>
                <span className="gredient-text pt-3 pb-1">Loading...</span>
              </div>
              <form>
              <h2>Reset Password</h2>
             
              <div className="position-relative">
                
                <input 
                  name="username"
                  value={email}
                  autocomplete="off"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                  }}
                />
                <label className="email-lable" for="username">Email address</label>
                </div>
                      
                {/* <span className="error-message gredient-text">{error}</span> */}
                <div className="login-page-submit justify-content-end">
                  {/* <Link to="Login">
                    <button className="f-password">
                      Forgot password?
                    </button>
                  </Link> */}
                  <span className="" onClick={forgotpasswordAPICALL} type="submit" id="submit">
                    {/* <span className="gredient-text">Sign In</span> */}
                    <span  className="quote"><span class="gredient-text">Send</span></span>
                  </span>
                </div>
              </form>
              
              <div className="term-checkbox">
                  
                    {/* <input  type="checkbox" id="cb01" />
                    <p >I agree to the <Link to="" className="gredient-text">Master Subscription Agreement.</Link></p> */}
              </div>
              
                
                
              <p>
                Don't have an account? <Link to="/signup" replace={false} className="gredient-text"> Sign up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgotpassword;
