import React, { useEffect, useState } from 'react';
import Images  from '../utils/theme/images';
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";




import { Link } from "react-router-dom";
import images from '../utils/theme/images';

export default function Header() {
  
  const [showNavColor, setShowNavColor] = useState(false);
  const [showNavColorSecond, setShowNavColorSecond] = useState(false);
  const [showNavColorThird, setShowNavColorThird] = useState(false);
 
  const [centredModal, setCentredModal] = useState(false);

  const toggleShow = () => setCentredModal(!centredModal);

 
  
  return (
    
    <>
   
      <MDBNavbar expand='lg' dark sticky  >
        <MDBContainer >
          <MDBNavbarBrand href='/' className='logo'><img src={Images.logo}  width={"auto"} height={"auto"} alt={"logo"}/></MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            data-target='#navbarColor02'
            aria-controls='navbarColor02'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNavColor(!showNavColor)}
            id='menu-icon'
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBCollapse show={showNavColor} navbar>
            <MDBNavbarNav className='m-auto mb-0 mt-0 mb-lg-0 w-auto'>
              <MDBNavbarItem>
                <MDBNavbarLink href='/#Home' className='_overview'>Overview</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink  href='/#Features' className='_features'>Features</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='/#Plan' className='_plan'>Plan</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='/#Faq' className='_faq'>Faq</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink  className='_contact' onClick={toggleShow}>Contact Us</MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
           
            <Link to="login" className='nav-Link'><button  className='quote'><span className='gredient-text'>Get Reeva</span></button></Link>
            
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>

     
     

       <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered size='xl'>
          <MDBModalContent>
            <MDBModalBody className='model'>
                <div className='row contact-form'>
                    <div className='col-md-4 contact-us-left '>
                        <div className='row h-100 align-items-end align-content-end'>
                            <img src={images.logo}  className='logo' />
                            <p>Try Oval free for 30 days to start connected with all your teams. Cancel any time.</p>
                            <div className='fotter-bottom mb-5'>
                                  <ul className='social-icon'>
                                    <li> 
                                        <a href='' className=' text-reset'>
                                        <MDBIcon fab icon="facebook-f" />
                                        </a>
                                    </li>
                                    <li> 
                                        <a href='' className=' text-reset'>
                                        <MDBIcon fab icon="twitter" />
                                        </a>
                                    </li>
                                    <li> 
                                        <a href='' className=' text-reset'>
                                        <MDBIcon fab icon="instagram" />
                                        </a>
                                      </li>
                                  </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8 contacy-us-right'>
                    <div className='text-end'>
                    <MDBBtn className='btn-close pt-4' color='none' onClick={toggleShow}></MDBBtn>
                    </div>
                        <div className='text-center mobile-contact-form'>
                        <img src={images.logo} />
                        </div>
                        <h2 className='text-start'>GET IN TOUCH</h2>
                        <p>24 X 7 We will answer your questions and problems</p>
                        <form className='contact-us-form'>
                        <div className='form-group log-in-form w-100 '>
                            <div className='form-input-row d-flex align-items-center'>
                                <div className="position-relative">
                                  <input 
                                    name="firstname"
                                    autocomplete="off"
                                    required
                                  />
                                  <label className="email-lable" for="firstname">First Name</label>
                                  </div>
                              
                                <div className="position-relative">
                                  <input 
                                    name="lastname"
                                    autocomplete="off"
                                    required
                                  />
                                  <label className="email-lable" for="lastname">Last Name</label>
                                  </div>
                                
                            </div>

                            <div className='row'>
                                <div className='input'>
                                <div className="position-relative">
                                  <input 
                                    name="email"
                                    autocomplete="off"
                                    required
                                  />
                                  <label className="email-lable" for="email">Email</label>
                                  </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='input'>
                                <div className="position-relative">
                                  <input 
                                    name="phone"
                                    autocomplete="off"
                                    required
                                  />
                                  <label className="email-lable" for="phone">Phone</label>
                                  </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='input'>
                                <div className="position-relative">
                                  <textarea 
                                    name="textarea"
                                    required
                                  />
                                  <label className="email-lable" for="textarea">Describe your issue</label>
                                  </div>
                                </div>
                            </div>
                            <span  className="" type="submit" id="submit">
                              {/* <span className="gredient-text">Sign In</span> */}
                              <span className="quote"><span class="gredient-text">Send</span></span>
                            </span>

                        </div>
                        </form>
                        <div className='fotter-bottom mb-5 mobile-contact-form'>
                                  <ul className='social-icon'>
                                    <li> 
                                        <a href='' className=' text-reset'>
                                        <MDBIcon fab icon="facebook-f" />
                                        </a>
                                    </li>
                                    <li> 
                                        <a href='' className=' text-reset'>
                                        <MDBIcon fab icon="twitter" />
                                        </a>
                                    </li>
                                    <li> 
                                        <a href='' className=' text-reset'>
                                        <MDBIcon fab icon="instagram" />
                                        </a>
                                      </li>
                                  </ul>
                        </div>
                    </div>
                </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
