
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import images from '../../utils/theme/images';
import axios from "axios";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { loginApiCall } from "../../utils/api/ApiAction";
import { message } from 'antd';

function Signup() {
  

 
    const [passwordType1, setPasswordType1] = useState("password");
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
    const togglePassword1 =()=>{
      if(passwordType1==="password")
      {
       setPasswordType1("text")
       return;
      }
      setPasswordType1("password")
    }
    

  
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const navigateTo = () => navigate("/Dashboard"); //eg.history.push('/login');

  useEffect(() => {
    console.log("email", email);
  }, [email]);

  const loader =(isload)=>{
    var element = document.getElementById("loader-body");
    if(isload){
      element.classList.remove("d-none");
    }else{
      element.classList.add("d-none");
    }
  }

  const messageAlert =(msg,isError)=>{
    message.open({
      type:  isError ? 'error' : 'success',
      content: msg,
      className: 'custom-class',
      duration: 2, 
      style: {
        marginTop: '120px',
      },
    });
  }

  const loginapi = async () => {
   
    setError("");
    if (email === "") {
      return;
    }

    if (pwd === "") {
      return;
    }
    
    loader(true);
    let payload = { username: email, password: pwd };
    
    let response = await loginApiCall(payload)
    console.log("abcd",response)
    if (response.status === 200) {
      messageAlert("login successfully...",false);
      navigateTo();
    } else {
     
    
      // message.error(response?.data?.message ?? "Something wrong")
      messageAlert(response?.data?.message ?? "Something wrong",true);
      setError(response?.data?.message ?? "Something wrong");
    }
    loader(false);
    
  };

  function nextStep (){
    var element1 = document.getElementById("stepone");
    var element2 = document.getElementById("steptwo");
      element2.classList.remove("d-none");
      element1.classList.add("d-none");
    }
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="login ">
    
      <div className="container">
        <div className="row align-items-center login-page-content">
          <div className="col-md-6">
            <img src={images.logo} />
            <h2 className="text-start m-0">
              Welcome to Reeva
            </h2>
            <p className="m-0 login-text">
              We are lorem ipsum team dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua.{" "}
            </p>
            <div className="user-joined">
              <div className="image-group">
                <img src={images.user1} />
                <img src={images.user2} />
                <img src={images.user3} />
              </div>
              <p className="m-0 p-0">
                3k+ people joined us, now it’s your turn
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="log-in-form position-relative step-one " id="stepone">
              <div className="position-absolute loader-body d-none" id="loader-body">
                <div class="loader-circle-2 ">
                  
                </div>
                <span className="gredient-text pt-3 pb-1">Loading...</span>
              </div>
              <form>
              <h2>Sign up</h2>
              <div className="form-input-row d-flex align-items-center">
                  <div className="position-relative">
                    <input 
                      name="FirstName"
                      // value={FirstName}
                      autocomplete="off"
                      required
                      // onChange={(e) => {
                      //   setFirstName(e.target.value);
                      //   setError("");
                      // }}
                    />
                    <label className="email-lable" for="FirstName">First Name</label>
                  </div>
                  <div className="position-relative">
                    <input 
                      name="LastName"
                      // value={LastName}
                      autocomplete="off"
                      required
                      // onChange={(e) => {
                      //   setLastName(e.target.value);
                      //   setError("");
                      // }}
                    />
                    <label className="email-lable" for="LastName">Last Name</label>
                  </div>
              </div>
              <div className="form-input-row d-flex align-items-center">
                  <div className="position-relative">
                    <input 
                      name="PhoneNo"
                      // value={FirstName}
                      autocomplete="off"
                      required
                      // onChange={(e) => {
                      //   setFirstName(e.target.value);
                      //   setError("");
                      // }}
                    />
                    <label className="email-lable" for="PhoneNo">Phone No.</label>
                  </div>

                  <div className="position-relative">
                  <input 
                    name="BrokerLicenseNo"
                    // value={email}
                    autocomplete="off"
                    required
                    // onChange={(e) => {
                    //   setEmail(e.target.value);
                    //   setError("");
                    // }}
                  />
                  <label className="email-lable" for="BrokerLicenseNo ">License No</label>
                </div>
              </div>

              <div className="position-relative">
                
                <input 
                  name="username"
                  // value={email}
                  autocomplete="off"
                  required
                  // onChange={(e) => {
                  //   setEmail(e.target.value);
                  //   setError("");
                  // }}
                />
                <label className="email-lable" for="username">Email address</label>
                </div>
                <div className="position-relative">
                  <input 
                    name="Address"
                    // value={email}
                    autocomplete="off"
                    required
                    // onChange={(e) => {
                    //   setEmail(e.target.value);
                    //   setError("");
                    // }}
                  />
                  <label className="email-lable" for="Address">Address </label>
                </div>
                <div className="form-input-row d-flex align-items-center">
                      <div className="position-relative">
                        <input
                          name="password"
                          // value={pwd}
                          required
                          autocomplete="off"
                          type={passwordType} id="password"
                          // onChange={(e) => {
                          //   setPwd(e.target.value);
                          //   setError("");
                          // }}
                        />
                        <label className="email-lable" for="password">Password</label>
                        <div className="pass-show toggle-password" onClick={togglePassword}> { passwordType==="password"? <BsFillEyeFill /> :<BsFillEyeSlashFill /> }</div>
                      </div>
                      <div className="position-relative">
                        <input
                          name="password"
                          // value={pwd}
                          required
                          autocomplete="off"
                          type={passwordType1} id="password"
                          // onChange={(e) => {
                          //   setPwd(e.target.value);
                          //   setError("");
                          // }}
                        />
                        <label className="email-lable" for="password">Password</label>
                        <div className="pass-show toggle-password" onClick={togglePassword1}> { passwordType1==="password"? <BsFillEyeFill /> :<BsFillEyeSlashFill /> }</div>
                      </div>
                </div>      
                {/* <span className="error-message gredient-text">{error}</span> */}
                <div className="login-page-submit justify-content-center">
                  {/* <Link to="Login">
                    <button className="f-password">
                      Forgot password?
                    </button>
                  </Link> */}
                  <span className="text-center" type="submit" id="submit">
                    {/* <span className="gredient-text">Sign In</span> */}
                    <span onClick={nextStep} className="quote text-center"><span class="gredient-text">Start Now</span></span>
                  </span>
                </div>
              </form>
              
              <div className="term-checkbox">
                  
                    <input  type="checkbox" id="cb01" />
                    <p >I agree to the <Link to="" className="gredient-text">Master Subscription Agreement.</Link></p>
              </div>
              
                
                
              <p>
              Already have an  account? <Link to="/login" replace={false} className="gredient-text">  Sign in</Link>
              </p>
            </div>



            
            <div className="log-in-form position-relative step-two d-none" id="steptwo">
              <div className="position-absolute loader-body d-none" id="loader-body">
                <div class="loader-circle-2 ">
                  
                </div>
                <span className="gredient-text pt-3 pb-1">Loading...</span>
              </div>
              <form>
              <h2>Sign up</h2>

                  <div className="position-relative">
                    <input 
                      name="BrokerName"
                      // value={LastName}
                      autocomplete="off"
                      required
                      // onChange={(e) => {
                      //   setLastName(e.target.value);
                      //   setError("");
                      // }}
                    />
                    <label className="email-lable" for="BrokerName">Broker Name</label>
                  </div>

                <div className="position-relative">
                  <input 
                    name="BrokerAddress"
                    // value={email}
                    autocomplete="off"
                    required
                    // onChange={(e) => {
                    //   setEmail(e.target.value);
                    //   setError("");
                    // }}
                  />
                  <label className="email-lable" for="BrokerAddress">Broker Address </label>
                </div>

                <div className="position-relative">
                  <input 
                    name="BrokerLicenseNo"
                    // value={email}
                    autocomplete="off"
                    required
                    // onChange={(e) => {
                    //   setEmail(e.target.value);
                    //   setError("");
                    // }}
                  />
                  <label className="email-lable" for="BrokerLicenseNo ">Broker License No</label>
                </div>

                <div className="form-input-row d-flex align-items-center">
                      <div className="position-relative">
                        <input 
                          name="Supervisorname"
                          // value={email}
                          autocomplete="off"
                          required
                          // onChange={(e) => {
                          //   setEmail(e.target.value);
                          //   setError("");
                          // }}
                        />
                        <label className="email-lable" for="Supervisorname">Supervisor name </label>
                      </div>

                      <div className="position-relative">
                          <input 
                            name="Supervisorlicensenumber"
                            // value={email}
                            autocomplete="off"
                            required
                            // onChange={(e) => {
                            //   setEmail(e.target.value);
                            //   setError("");
                            // }}
                          />
                          <label className="email-lable" for="Supervisorlicensenumber">Supervisor license number </label>
                      </div>
                </div> 

                <div className="form-input-row d-flex align-items-center">
                      <div className="position-relative">
                        <input 
                          name="Preferredtitlecompany "
                          // value={email}
                          autocomplete="off"
                          required
                          // onChange={(e) => {
                          //   setEmail(e.target.value);
                          //   setError("");
                          // }}
                        />
                        <label className="email-lable" for="Preferredtitlecompany ">Preferred title company  </label>
                      </div>

                      <div className="position-relative">
                          <input 
                            name="Preferredtitlecompanyaddress "
                            // value={email}
                            autocomplete="off"
                            required
                            // onChange={(e) => {
                            //   setEmail(e.target.value);
                            //   setError("");
                            // }}
                          />
                          <label className="email-lable" for="Preferredtitlecompanyaddress ">Preferred title company address  </label>
                      </div>
                </div>   

                {/* <span className="error-message gredient-text">{error}</span> */}
                <div className="login-page-submit justify-content-center">
                  {/* <Link to="Login">
                    <button className="f-password">
                      Forgot password?
                    </button>
                  </Link> */}
                  <span  className="" type="submit" id="submit">
                    {/* <span className="gredient-text">Sign In</span> */}
                    <span className="quote text-center"><span class="gredient-text">Signup Now</span></span>
                  </span>
                </div>
              </form>
              
              <div className="term-checkbox">
                  
                    <input checked  type="checkbox" id="cb01" />
                    <p >I agree to the <Link to="" className="gredient-text">Master Subscription Agreement.</Link></p>
              </div>
              
                
                
              <p>
              Already have an  account? <Link to="/login" replace={false} className="gredient-text">  Sign in</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
