
import React, { useState, useEffect, useRef } from "react";
import images from '../utils/theme/images';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
import { createRef } from "react";
import { useCallback } from "react";
export default () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 100,
        fade: true,
        autoplay: true,
        pauseOnHover: false,
      autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        arrows: false,
        verticalSwiping: true,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                verticalSwiping: true,
                vertical: true,
              },
            },
            {
              breakpoint: 480,
              settings: {
                verticalSwiping: false,
                autoplaySpeed: 3000,
                vertical: false,
                speed: 500,
              },
            },
          ],
    };
   

    const sliderRef = createRef();
const scroll = useCallback(
    y => {


        
      if (y > 0) {
        return sliderRef?.current?.slickNext(); /// ? <- using description below 
      } else {
        return sliderRef?.current?.slickPrev();
      }
    },
    [sliderRef]
  );
 useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(!isMobile){     
        window.addEventListener("wheel", e => {
          e.preventDefault();
          scroll(e.deltaY);
        });
    }
  }, [scroll]);

  

    return (


        <div className="swiper-vertilcal">

            <Slider {...settings} ref={ sliderRef }  >
                <div className="swiper-ver-slide">
                    <div className="row align-items-center w-100">
                        <div className="col-md-6 text-center ">
                            <img src={images.slide1}  className="animate-img"/>
                            
                        </div>
                        <div className="col-md-6 transform-slide">
                        <div className="para-head">
                            <h2>
                                AI-Generated Contracts
                            </h2>
                            </div>
                            <div className="para-div">
                            <p>Say goodbye to manual drafting and hello to REEVA. Our AI-powered technology generates Residential  Contracts in minutes, saving you time and effort.
                            </p>
                            </div>
                            <ul>
                            <li><a href='https://www.google.com/' target='blank'><img src={images.playstore} alt={"playstore"} className='app-image' /></a></li>
                        <li><a href='https://www.google.com/' target='blank'><img src={images.appstore} alt={"appstore"} className='app-image' /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="swiper-ver-slide">
                    <div className="row align-items-center w-100">
                        <div className="col-md-6 text-center ">
                            <img src={images.slide2}  className="animate-img"/>
                           
                        </div>
                        <div className="col-md-6 transform-slide">
                        <div className="para-head">
                            <h2>
                                Smart Contact Management
                            </h2>
                            </div>
                            <div className="para-div">
                            <p>Import, create, and manage contacts effortlessly with REEVA. Seamlessly send contracts and emails to your contacts within seconds, streamlining your communication process and maximizing efficiency.
                            </p>
                            </div>
                            <ul>
                            <li><a href='https://www.google.com/' target='blank'><img src={images.playstore} alt={"playstore"} className='app-image' /></a></li>
                        <li><a href='https://www.google.com/' target='blank'><img src={images.appstore} alt={"appstore"} className='app-image' /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="swiper-ver-slide">
                    <div className="row align-items-center w-100">
                        <div className="col-md-6 text-center ">
                            <img src={images.slide3}  className="animate-img"/>
                            
                        </div>
                        <div className="col-md-6 transform-slide">
                        <div className="para-head">
                            <h2>
                                Effortless Email Templates
                            </h2>
                            </div>
                            <div className="para-div">
                            <p>REEVA's intelligent email templates ensure personalized and relevant communication with your clients and vendors - sent within seconds.
                            </p>
                            </div>
                            <ul>
                            <li><a href='https://www.google.com/' target='blank'><img src={images.playstore} alt={"playstore"} className='app-image' /></a></li>
                        <li><a href='https://www.google.com/' target='blank'><img src={images.appstore} alt={"appstore"} className='app-image' /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="swiper-ver-slide">
                    <div className="row align-items-center w-100">
                        <div className="col-md-6 text-center ">
                            <img src={images.slide4}  className="animate-img"/>
                           
                        </div>
                        <div className="col-md-6 transform-slide">
                        <div className="para-head">
                            <h2>
                                Advanced Voice Control
                            </h2>
                            </div>
                            <div className="para-div">
                            <p>Control REEVA seamlessly through voice. Conversations on-the-go enable REEVA's AI engine to turn your words into professional outputs.
                            </p>
                            </div>
                            <ul>
                            <li><a href='https://www.google.com/' target='blank'><img src={images.playstore} alt={"playstore"} className='app-image' /></a></li>
                        <li><a href='https://www.google.com/' target='blank'><img src={images.appstore} alt={"appstore"} className='app-image' /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Slider>

        </div>





    );

};