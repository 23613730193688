
import Footer from '../../components/footer';
import images from '../../utils/theme/images';
import Slide from '../../components/slide'
import ReactFullpage from "@fullpage/react-fullpage";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect,useRef} from "react";
import Faq from "react-faq-component";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Slider from '../../components/slider';
import { MDBSwitch } from 'mdb-react-ui-kit';
import { MDBAccordion, MDBAccordionItem , MDBCarousel,MDBCarouselItem,} from 'mdb-react-ui-kit';

function App() {
  const SectionStyle ={
    height : '100vh',
    width : '100%' ,
    display : 'flex',
    alignItems : 'center',
    justifyContent : 'center',
}

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
const anchors = ["Home", "Features", "Plan", "Faq"];



window.onload = function() {
  check();
  var tab = document.getElementsByClassName("react-tabs__tab");
  tab.addEventListener("click", check);
};

function check() {
  var checkBox = document.getElementById("flexSwitchCheckChecked");
  var text1 = document.getElementsByClassName("text1");
  var text2 = document.getElementsByClassName("text2");

  for (var i = 0; i < text1.length; i++) {
    if (checkBox.checked == true) {
      text1[i].style.display = "block";
      text2[i].style.display = "none";
    } else if (checkBox.checked == false) {
      text1[i].style.display = "none";
      text2[i].style.display = "block";
    }
  }
}
check();
return (
    <>

<ReactFullpage
    anchors={anchors}
    navigation
    navigationTooltips={anchors}
    onLeave={(origin, destination, direction) => {
      
    }}
    render={({ state, fullpageApi }) => {
      return (
        <div>
          <div className="section fp-auto-height" id="home">
              <section className='banner-sec fp-auto-height panel' id="home" style={SectionStyle}>
              <div className='container'>
                  <div className='row align-items-center'>
                      <div className='col-md-6'>
                          <h1 className='drop-in'>Meet REEVA - Your AI-Powered Real Estate <span className='last-word'>Sidekick</span></h1>
                          <p className='drop-in-2'>Revolutionize Your Real Estate Business with the Ultimate Virtual Assistant</p>
                          <ul className='drop-in-3'>
                              <li><a href='https://www.google.com/' target='blank'><img src={images.playstore} alt={"playstore"} className='app-image' /></a></li>
                              <li><a href='https://www.google.com/' target='blank'><img src={images.appstore} alt={"appstore"} className='app-image' /></a></li>
                          </ul>
                      </div>
                      <div className='col-md-6 text-center padding-responsive'>
                          <div className='banner-position-img'>
                              <img src={images.firstimg} alt={""} className='topimg'/>
                              <img src={images.secondimg} alt={""}  className='behindimg' id='behindimg'/>
                              <img src={images.bgvector} alt={""} className='bgvector'/>
                              <div className='bg-circle'></div>
                          </div>
                      </div>
                  </div>
              </div>
            </section>
          </div>

          <div className="section fp-auto-height" id='features'>
            <section className='vertical-slider position-relative fp-auto-height panel overflow-hidden' style={SectionStyle} >
                <div className="gredient-left"></div>
                  <div className='container'>
                      <Slider/>
                </div>
            </section>
          </div>

          <div className="section fp-auto-height " id="plan">
              <section className='plan fp-auto-height panel' id="plan" style={SectionStyle}>
              <div className='container'>
                  <h2>Choose Your Perfect Plan</h2>
                  <ul className='toggle-switch'>
                    <li><p className='m-0'>Monthly Plans</p></li>
                    <li> <MDBSwitch defaultChecked id='flexSwitchCheckChecked' onClick={check} /></li>
                    <li>Annual Plans</li>
                  </ul>
                  <div className=' pancard-gap plan-desktop'>
                      <div className='plancard'>
                          <div className='plan-card'>
                              <div>
                                <div className='plan-card-header'>
                                    <ul>
                                      <li><h5>Free</h5><span>Up to 20 people</span></li>
                                      <li><h4 className='text1'>$0</h4><h4 className='text2'>$0</h4></li>
                                    </ul>
                                </div>
                                <div className='plan-card-body'>
                                    <ul className='condition'>
                                      <li>3 Offers / mo.</li>
                                      <li>10 Emails / mo.</li>
                                      <li>Unlimited Contacts & Templates</li>
                                      <li>Essentials card</li>
                                    </ul>
                                </div>
                              </div>
                              <div>
                                <button className='start-free quote'><span className='gredient-text'>Start Free</span></button>
                              </div>  
                          </div>
                      </div>
                      <div className='plancard center-card'>
                      <div className='plan-card position-relative'>
                      <div>
                                <div className='recommended'><span>Recommended</span></div>
                                <div className='plan-card-header'>
                                    <ul>
                                      <li><h5>Plus</h5></li>
                                      <li><h4 className='text1'>$14.99</h4><h4 className='text2'>$8.99</h4><span>Month</span></li>
                                    </ul>
                                </div>
                                <div className='plan-card-body'>
                                    <ul className='condition'>
                                      <li>Unlimited Offers</li>
                                      <li>Unlimited Emails</li>
                                      <li>Unlimited Contacts & Templates</li>
                                    </ul>
                                </div>
                                </div>
                                <button className='start-free quote'><span className='gredient-text'>Buy Now</span></button>
                                {/* <button className='buy-now'>Buy Now</button> */}
                          </div>
                      </div>
                      <div className='plancard'>
                      <div className='plan-card'>
                                <div>
                                <div className='plan-card-header'>
                                    <ul>
                                      <li><h5>Elite</h5></li>
                                      <li><h4 className='text1'>$30.9</h4><h4 className='text2'>$14.9</h4><span>Month</span></li>
                                    </ul>
                                </div>
                                <div className='plan-card-body'>
                                    <ul className='condition'>
                                      <li>Unlimited Offers</li>
                                      <li>Unlimited Contacts & Templates</li>
                                      <li>Unlimited Emails</li>
                                      <li>Talk to REEVA (Voice)</li>
                                    </ul>
                                </div>
                                </div>
                                <button className='start-free quote'><span className='gredient-text'>Buy Now</span></button>
                                {/* <button className='buy-now'>Buy Now</button> */}
                          </div>
                      </div>
                  </div>
                
                  <div className='plan-mobile'>
                   <Slide />
                    </div>
              </div>
          </section>
          </div>

          <div className="section fp-auto-height" id="faq" >
              <section className='faqsec fp-auto-height panel' id="faq" style={SectionStyle}>
              <div className='left-bottom'></div>
              <div className='top-right'></div>
              <div className='container position-relative ellipse-container'>
                        <div className='position-ellips'>
                            <div className='elips-img'>
                              <img src={images.ellips} className='elips-image' />
                              <div className='up-side-ellips'>
                                  <img src={images.upellips} />
                              </div>
                              <div className='bottom-side-ellips'>
                                  <img src={images.bottomellips} />
                              </div>
                              <div className='customer-service'>
                                  
                                    <div className='service-ellips'><img src={images.serviceellips} /></div>
                                    <p>24/7 Coustemer services</p>
                                  
                              </div>
                              <div className='orange-dot'>

                              </div>
                              <div className='green-dot'>

                              </div>
                          </div>
                          </div>
                  <div className='row justify-content-end align-items-center faq-right w-100'> 
                      <div className='col-md-7'> 
                          <h2 className='line-bottom'>Frequently Asked Questions</h2>
                          <div>
                          
                              {/* <Faq
                                  data={data}
                                  styles={styles}
                                  config={config}

                              /> */}
                              <MDBAccordion  flush initialActive={1}>
                                <MDBAccordionItem collapseId={1} headerTitle='Can i cancel my essentials or premium plan subscription at anytime?'>
                                You can pay with a credit car or via net banking (if you are in Poland). We will renew your subscription automatically at the end of every billing cycle.
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={2} headerTitle='How do i pay for the essentials or premium plan?'>
                                You can pay with a credit car or via net banking (if you are in Poland). We will renew your subscription automatically at the end of every billing cycle.
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={3} headerTitle='We need to add new users to our team. How will that be billed?'>
                                You can pay with a credit car or via net banking (if you are in Poland). We will renew your subscription automatically at the end of every billing cycle.
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={4} headerTitle='My teams wants to cancel it is subcription, can we get a refund?'>
                                You can pay with a credit car or via net banking (if you are in Poland). We will renew your subscription automatically at the end of every billing cycle.
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={5} headerTitle='Do you offer discounts for non fropit organizations or educational institutions?'>
                                You can pay with a credit car or via net banking (if you are in Poland). We will renew your subscription automatically at the end of every billing cycle.
                                </MDBAccordionItem>
                              </MDBAccordion>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          </div>

          <div className="section fp-auto-height" id='contact'>
                <Footer/>
          </div>
          
        </div>
      );
    }}
  />

   
  
    </>

  );
}

export default App;
