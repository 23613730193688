import axios from "axios";


export const loginApiCall = async (payload) => {

    const response = await axios.post("auth/login", payload, { validateStatus: (status) => true});

    return response;
}
export const forgotpasswordapi = async (payload) => {

    const response = await axios.post("auth/login", payload, { validateStatus: (status) => true});

    return response;
}
export const otpapi = async (payload) => {

    const response = await axios.post("auth/login", payload, { validateStatus: (status) => true});

    return response;
}
export const setnewpassword = async (payload) => {

    const response = await axios.post("auth/login", payload, { validateStatus: (status) => true});

    return response;
}