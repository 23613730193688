import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import images from '../utils/theme/images';
import { Link, useNavigate,useLocation } from "react-router-dom";
const FModel = ({setOpen, isOpen}) => {
  const [open, setIsOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');
  const location = useLocation();

useEffect(()=>{
  setIsOpen(isOpen)
  console.log("isopen",isOpen)
},[isOpen])

  const handleOk = () => {
    setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      handleCancel();
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
    setIsOpen(false);
  };
  const isSetNewPasswordPage = location.pathname === '/setnewpassword';
  const isForgotPasswordPage = location.pathname === '/forgotpassword';
  return (
    <>
      <Modal
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        centered
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div className="clone-model">
          {isSetNewPasswordPage && (
            <div className="password-change text-center">
              <div>
                <img src={images.passChanged} alt="Password Changed" />
              </div>
              <h5>Password Changed!</h5>
              <p>Your password has been changed successfully.</p>
              <Link to="/login" replace={false}>
                <span className="quote ">
                  <span className="gredient-text">Sign In</span>
                </span>
              </Link>
            </div>
          )}

          {isForgotPasswordPage && (
            <div className="email-not-found text-center">
              <div>
                <img src={images.email404} alt="Email Not Found" />
              </div>
              <h5>Email not found</h5>
              <p>No Account found registered with your email address</p>
              <Link to="/signup" replace={false}>
                <span className="quote ">
                  <span className="gredient-text">Sign Up</span>
                </span>
              </Link>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
export default FModel;