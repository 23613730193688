import React from 'react';
import images from '../utils/theme/images';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { useFormik } from 'formik';
import { Link } from "react-router-dom";
import { useState } from 'react';


const validate = (values) => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  return errors
}


export default function Footer() {
 
    const formik = useFormik({
        initialValues: {
          email: '',
        },
        validate,
        onSubmit: (values) => {
          alert(JSON.stringify(values, null, 2))
        },
      })
  return (
    <MDBFooter  className='text-center text-lg-start ' id="contact">
      <section className='footer'>
        <MDBContainer className='text-center text-md-start'>
          <MDBRow className='mt-3'>
            <MDBCol md="4" lg="4" xl="4" className=''>
              <h6 className='logo fw-bold m-0'>
              <img src={images.logolight} alt={"logo"} />
              </h6>
              <p>
              Try Oval free for 30 days to start connected with all your teams. Cancel any time.
              </p>
              {/* <div className='f-input'>
                <input type="text" name="email" placeholder="Enter your email" id='email' className=' f-email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} /><button className='gr-send' type='submit' name='send'>< GrSend /></button></div>
                {formik.touched.email && formik.errors.email && (
                <p>{formik.errors.email}</p>
                 )}  */}
                 
            </MDBCol>

           

            <MDBCol md="2" lg="2" xl="2" className=' '>
              <h6 className=' fw-bold m-0'>Our Link</h6>
              
                <a href='/#Home' className='text-reset'>
                Overview
                </a>
             
                <a href='/#Features' className='text-reset'>
                Features
                </a>
             
                <a href='/#Plan' className='text-reset'>
                Plans
                </a>
             
                {/* <a href='#'  className='text-reset' >
                Contact
                </a> */}
              
             
            </MDBCol>


            <MDBCol md="2" lg="2" xl="2" className=' '>
            <h6 className=' fw-bold m-0'>Get In Touch</h6>
              
              <a href='#!' className='text-reset'>
              <Link to="/terms" className='p-0' replace={false}>Terms & Conditions</Link>
              </a>
           
              <a href='#!' className='text-reset'>
              <Link to="/privacypolicy" className='p-0' replace={false}>Privacy Policy</Link>
              </a>

              <Link to="login" className=''><button  className='quote '><span className='gredient-text'>Get Reeva</span></button></Link>
           
              
              
              </MDBCol>
            <MDBCol md="4" lg="4" xl="4" className=' '>
            <h6 className=' fw-bold m-0 text-center'>Download Our App</h6>
               <ul className='m-0 p-0 text-center app-link'>
               <li><a href='https://www.google.com/' target='blank'><img src={images.playstore} alt={"playstore"} className='app-image' /></a></li>
                <li><a href='https://www.google.com/' target='blank'><img src={images.appstore} alt={"appstore"} className='app-image' /></a></li>
               </ul>
            </MDBCol>
          </MDBRow>
          <div className='fotter-bottom'>
                <ul className='social-icon'>
                  <li> 
                      <a href='' className='me-4 text-reset'>
                       <MDBIcon fab icon="facebook-f" />
                      </a>
                  </li>
                  <li> 
                      <a href='' className='me-4 text-reset'>
                      <MDBIcon fab icon="twitter" />
                      </a>
                  </li>
                  <li> 
                      <a href='' className='me-4 text-reset'>
                      <MDBIcon fab icon="instagram" />
                      </a>
                    </li>
                </ul>
                <p className='m-0 p-0 copyright' style={{color:"#fff"}}> © 2023 <a href='#' className='m-0 p-0' target='blank' style={{color: '#9F7CFB',display:'inline-block'}}>Reeva.</a> All rights reserved.</p>

          </div>
        </MDBContainer>
      </section>
    </MDBFooter>

    
  );
}