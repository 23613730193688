

import images from '../../utils/theme/images';


import React, { useState, useEffect,useRef} from "react";


function Dashboard() {
  

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
 






  return (
    <>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

    <h1>dashboard</h1>
   
  
    </>

  );
}

export default Dashboard;
