
import React, { useState, useEffect, useRef } from "react";
import images from '../utils/theme/images';


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import $ from "jquery";

export default () => {
    var settings = {
        infinite: false,
        speed: 500,
        fade: false,
        autoplay: false,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false,
        arrows: true,
        verticalSwiping: false,
    };
   

    

  

    return (


        <div className="swiper-vertilcal">

            <Slider {...settings}  >
                <div className="swiper-ver-slide">
                        <div className='plancard'>
                          <div className='plan-card'>
                              <div>
                                <div className='plan-card-header'>
                                    <ul>
                                      <li><h5>Free</h5><span>Up to 20 people</span></li>
                                      <li><h4 className='text1'>$0</h4><h4 className='text2'>$0</h4></li>
                                    </ul>
                                </div>
                                <div className='plan-card-body'>
                                    <ul className='condition'>
                                      <li>3 Offers / mo.</li>
                                      <li>10 Emails / mo.</li>
                                      <li>Unlimited Contacts & Templates</li>
                                      <li>Essentials card</li>
                                    </ul>
                                </div>
                              </div>
                              <div>
                                <button className='start-free quote'><span className='gredient-text'>Start Free</span></button>
                              </div>  
                          </div>
                        </div>
                </div>

                <div className="swiper-ver-slide">
                <div className='plancard center-card'>
                      <div className='plan-card position-relative'>
                      <div>
                                <div className='recommended'><span>Recommended</span></div>
                                <div className='plan-card-header'>
                                    <ul>
                                      <li><h5>Plus</h5></li>
                                      <li><h4 className='text1'>$14.99</h4><h4 className='text2'>$8.99</h4><span>Month</span></li>
                                    </ul>
                                </div>
                                <div className='plan-card-body'>
                                    <ul className='condition'>
                                      <li>Unlimited Offers</li>
                                      <li>Unlimited Emails</li>
                                      <li>Unlimited Contacts & Templates</li>
                                    </ul>
                                </div>
                                </div>
                                <button className='start-free quote'><span className='gredient-text'>Buy Now</span></button>
                                {/* <button className='buy-now'>Buy Now</button> */}
                          </div>
                      </div>
                </div>

                <div className="swiper-ver-slide">
                <div className='plancard'>
                      <div className='plan-card'>
                                <div>
                                <div className='plan-card-header'>
                                    <ul>
                                      <li><h5>Elite</h5></li>
                                      <li><h4 className='text1'>$30.9</h4><h4 className='text2'>$14.9</h4><span>Month</span></li>
                                    </ul>
                                </div>
                                <div className='plan-card-body'>
                                    <ul className='condition'>
                                      <li>Unlimited Offers</li>
                                      <li>Unlimited Contacts & Templates</li>
                                      <li>Unlimited Emails</li>
                                      <li>Talk to REEVA (Voice)</li>
                                    </ul>
                                </div>
                                </div>
                                <button className='start-free quote'><span className='gredient-text'>Buy Now</span></button>
                                {/* <button className='buy-now'>Buy Now</button> */}
                          </div>
                      </div>
                </div>


            </Slider>

        </div>





    );

};