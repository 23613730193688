
import './utils/css/App.css';
import './utils/css/responsive.css'
import Header from "./components/header";
import Footer from "./components/footer.js";
import './utils/css/font.css'
import 'react-tabs/style/react-tabs.css';
import { BrowserRouter ,Routes, Route} from "react-router-dom";
import Home from "./navigation/rootStack/home";
import PrivacyPolicy from './navigation/rootStack/privacypolicy.js';
import Terms from './navigation/rootStack/terms.js';
import Slide from './components/slide';
import Login from './navigation/loginStack/login'
import Otp from './navigation/loginStack/otp';
import Signup from './navigation/loginStack/signup'
import ForgotPassword  from './navigation/loginStack/forgotpassword';

import Dashboard from './navigation/rootStack/dashboard';
import { useEffect } from 'react';
import axios from 'axios';
import { message } from 'antd';
import SetNewPassword from './navigation/loginStack/setnewpassword';

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const SectionStyle ={
    height : '100vh',
    width : '100%' ,
    display : 'flex',
    alignItems : 'center',
    justifyContent : 'center',
}

useEffect(() => {
  axios.defaults.baseURL = "https://dummyjson.com/";
  axios.defaults.headers = { "Content-Type": "application/json" }
}, [])


  return (
    
    <div className="App">
      {contextHolder}
      <BrowserRouter>
      
      <div className="Apps">
      <Header/>
      <Routes>
      
        <Route path="/" element={ <Home /> } />
        <Route path="privacypolicy" element={ <PrivacyPolicy /> } />
        <Route path="terms" element={ <Terms /> } />
        <Route path="slide" element={ <Slide /> } />
        <Route path="login" element={ <Login /> } />
        <Route path="signup" element={ <Signup /> } />
        <Route path="forgotpassword" element={ <ForgotPassword /> } />
        <Route path="otp" element={ <Otp /> } />
        <Route path="setnewpassword" element={ <SetNewPassword /> } />

        <Route path="dashboard" element={ <Dashboard /> } />
       
      </Routes>
      
      {/* <Footer /> */}
    </div>

      </BrowserRouter>
      
    </div>
  );
}

export default App;
