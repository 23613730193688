
import images from '../../utils/theme/images';

import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { loginApiCall } from "../../utils/api/ApiAction";
import { setnewpassword } from "../../utils/api/ApiAction";
import { message } from 'antd';
import FModel from "../../components/model";

function SetNewPassword() {
  

 
    const [passwordType1, setPasswordType1] = useState("password");
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
    const togglePassword1 =()=>{
        if(passwordType1==="password")
        {
         setPasswordType1("text")
         return;
        }
        setPasswordType1("password")
      }
    
    

  
  const [pwdConfirm, setPwdConfirm] = useState("");
  const [pwd, setPwd] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const navigateTo = () => navigate("/Dashboard"); //eg.history.push('/login');

  // useEffect(() => {
  //   console.log("email", email);
  // }, [email]);

  const loader =(isload)=>{
    var element = document.getElementById("loader-body");
    if(isload){
      element.classList.remove("d-none");
    }else{
      element.classList.add("d-none");
    }
  }

  const messageAlert =(msg,isError)=>{
    message.open({
      type:  isError ? 'error' : 'success',
      content: msg,
      className: 'custom-class',
      duration: 2, 
      style: {
        marginTop: '120px',
      },
    });
  }

  const setnewpassword = async () => {
   
    setError("");
    if (pwd === "") {
      return;

    if (pwdConfirm === "") 
      return;

      
    }
    if (pwdConfirm !== pwd ) {
      messageAlert("Password Does Not Match....",true);
      return;
    }
    setOpen(true)
    return
    loader(true);
    let payload = {  password: pwd , password: pwdConfirm};
    
    let response = await loginApiCall(payload)
    console.log("abcd",response)
    if (response.status === 200) {
      messageAlert("Password changed successfully...",false);
      setOpen(true)
      navigateTo();
    } else {
     
    
      // message.error(response?.data?.message ?? "Something wrong")
      messageAlert(response?.data?.message ?? "Password Does Not Match..",true);
      setError(response?.data?.message ?? "Password Does Not Match..");
      
    }
    loader(false);
    
  };

  
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="login setNewPassWord">
    <FModel isOpen={open} setOpen={setOpen} />
      <div className="container">
        <div className="row align-items-center login-page-content">
          <div className="col-md-6">
            <img src={images.logo} />
            <h2 className="text-start m-0">
            Set new password 
            </h2>
            <p className="m-0 login-text">
            Once you have successfully verified your identity using the OTP sent to your registered email address, you will be able to set a new password for your account.
            </p>
            <div className="user-joined">
              <div className="image-group">
                <img src={images.user1} />
                <img src={images.user2} />
                <img src={images.user3} />
              </div>
              <p className="m-0 p-0">
                3k+ people joined us, now it’s your turn
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="log-in-form position-relative step-one ">
              <div className="position-absolute loader-body d-none" id="loader-body">
                <div class="loader-circle-2 ">
                  
                </div>
                <span className="gredient-text pt-3 pb-1">Loading...</span>
              </div>
              <form>
              <h2>Reset Password</h2>
                    <div className="position-relative">
                        <input
                          name="password"
                          value={pwd}
                          required
                          autocomplete="off"
                          type={passwordType} id="password"
                          onChange={(e) => {
                            setPwd(e.target.value);
                            setError("");
                          }}
                        />
                        <label className="email-lable" for="password">Password</label>
                        <div className="pass-show toggle-password" onClick={togglePassword}> { passwordType==="password"? <BsFillEyeFill /> :<BsFillEyeSlashFill /> }</div>
                    </div>

                    <div className="position-relative">
                        <input
                          name="Confirmpassword"
                          value={pwdConfirm}
                          required
                          autocomplete="off"
                          type={passwordType1} id="password"
                          onChange={(e) => {
                            setPwdConfirm(e.target.value);
                            setError("");
                          }}
                        />
                        <label className="email-lable" for="Confirmpassword">Confirm password</label>
                        <div className="pass-show toggle-password" onClick={togglePassword1}> { passwordType1==="password"? <BsFillEyeFill /> :<BsFillEyeSlashFill /> }</div>
                    </div>
              
                      
                {/* <span className="error-message gredient-text">{error}</span> */}
                <div className="login-page-submit justify-content-end">
                  {/* <Link to="Login">
                    <button className="f-password">
                      Forgot password?
                    </button>
                  </Link> */}
                 
                  <span className="" type="submit" id="submit">
                    {/* <span className="gredient-text">Resend otp in 01:32 min</span> */}
                    <span onClick={setnewpassword}  className="quote "><span class="gredient-text">Reset Now</span></span>
                  </span>
                </div>
              </form>
              
              <div className="term-checkbox">
                  
                    {/* <input  type="checkbox" id="cb01" />
                    <p >I agree to the <Link to="" className="gredient-text">Master Subscription Agreement.</Link></p> */}
              </div>
              
                
                
              <p>
              Remember your password?  <Link to="/login" replace={false} className="gredient-text"> Sign in</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetNewPassword;
