
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import images from '../../utils/theme/images';
import axios from "axios";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { otpapi } from "../../utils/api/ApiAction";
import { loginApiCall } from "../../utils/api/ApiAction";
import { message } from 'antd';
import OtpInput from 'react-otp-input';
function Otp() {
  

 
    const [otp, setOtp] = useState('');
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
    

  
  // const [email, setEmail] = useState("");
  // const [pwd, setPwd] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const navigateTo = () => navigate("/setnewpassword"); //eg.history.push('/login');

  useEffect(() => {
    console.log("otp", otp);
  }, [otp]);

  const loader =(isload)=>{
    var element = document.getElementById("loader-body");
    if(isload){
      element.classList.remove("d-none");
    }else{
      element.classList.add("d-none");
    }
  }

  const messageAlert =(msg,isError)=>{
    message.open({
      type:  isError ? 'error' : 'success',
      content: msg,
      className: 'custom-class',
      duration: 2, 
      style: {
        marginTop: '120px',
      },
    });
  }

  const otpapi = async () => {
   
    setError("");
    if (otp === "") {
      return;
    }

    if (otp === "1111") {
      messageAlert("otp verified successfully...",false);
      navigateTo();
      return;
    }
    
    loader(true);
    let payload = { username: otp };
    
    let response = await loginApiCall(payload)
    console.log("abcd",response)
    if (response.status === 200) {
      messageAlert("login successfully...",false);
      navigateTo();
    } else {
     
    
      // message.error(response?.data?.message ?? "Something wrong")
      messageAlert(response?.data?.message ?? "Something wrong",true);
      setError(response?.data?.message ?? "Something wrong");
    }
    loader(false);
    
  };

  
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="login ">
    
      <div className="container">
        <div className="row align-items-center login-page-content">
          <div className="col-md-6">
            <img src={images.logo} />
            <h2 className="text-start m-0">
            OTP verification 
            </h2>
            <p className="m-0 login-text">
            To ensure the security of your account, we will send a One-Time Password (OTP) to your registered email address when you attempt to reset your password.
            </p>
            <div className="user-joined">
              <div className="image-group">
                <img src={images.user1} />
                <img src={images.user2} />
                <img src={images.user3} />
              </div>
              <p className="m-0 p-0">
                3k+ people joined us, now it’s your turn
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="log-in-form position-relative step-one ">
              <div className="position-absolute loader-body d-none" id="loader-body">
                <div class="loader-circle-2 ">
                  
                </div>
                <span className="gredient-text pt-3 pb-1">Loading...</span>
              </div>
              <form>
              <h2>Reset Password</h2>
             
              <div className="position-relative OtpInput">
                
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>&nbsp;</span>}
                renderInput={(props, index) => (
                    <input
                    {...props}
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    maxLength={1}
                    />
                )}
                />
                </div>
                      
                {/* <span className="error-message gredient-text">{error}</span> */}
                <div className="login-page-submit ">
                  {/* <Link to="Login">
                    <button className="f-password">
                      Forgot password?
                    </button>
                  </Link> */}
                  <span className="gredient-text" style={{"font-size": "13px"}}>Resend otp in 01:32 min</span>
                  <span className="" type="submit" id="submit">
                    {/* <span className="gredient-text">Resend otp in 01:32 min</span> */}
                    <span onClick={otpapi} className="  quote "><span class="gredient-text">Submit</span></span>
                  </span>
                </div>
              </form>
              
              <div className="term-checkbox">
                  
                    {/* <input  type="checkbox" id="cb01" />
                    <p >I agree to the <Link to="" className="gredient-text">Master Subscription Agreement.</Link></p> */}
              </div>
              
                
                
              <p>
              Remember your password?  <Link to="/login" replace={false} className="gredient-text"> Sign in</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
