
import images from '../../utils/theme/images';

import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { loginApiCall } from "../../utils/api/ApiAction";
import { message } from 'antd';

function Login() {
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const navigateTo = () => navigate("/Dashboard"); //eg.history.push('/login');

  useEffect(() => {
    console.log("email", email);
  }, [email]);

  const loader =(isload)=>{
    var element = document.getElementById("loader-body");
    if(isload){
      element.classList.remove("d-none");
    }else{
      element.classList.add("d-none");
    }
  }

  const messageAlert =(msg,isError)=>{
    message.open({
      type:  isError ? 'error' : 'success',
      content: msg,
      className: 'custom-class',
      duration: 2, 
      style: {
        marginTop: '120px',
      },
    });
  }

  const loginapi = async () => {
   
    setError("");
    if (email === "") {
      return;
    }

    if (pwd === "") {
      return;
    }
    
    loader(true);
    let payload = { username: email, password: pwd };
    
    let response = await loginApiCall(payload)
    console.log("abcd",response)
    if (response.status === 200) {
      messageAlert("login successfully...",false);
      navigateTo();
    } else {
     
    
      // message.error(response?.data?.message ?? "Something wrong")
      messageAlert(response?.data?.message ?? "Something wrong",true);
      setError(response?.data?.message ?? "Something wrong");
    }
    loader(false);
    
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="login ">
    
      <div className="container">
        <div className="row align-items-center login-page-content">
          <div className="col-md-6">
            <img src={images.logo} />
            <h2 className="text-start m-0">
              Welcome back <br></br>to Reeva
            </h2>
            <p className="m-0 login-text">
              We are lorem ipsum team dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua.{" "}
            </p>
            <div className="user-joined">
              <div className="image-group">
                <img src={images.user1} />
                <img src={images.user2} />
                <img src={images.user3} />
              </div>
              <p className="m-0 p-0">
                3k+ people joined us, now it’s your turn
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="log-in-form position-relative">
              <div className="position-absolute loader-body d-none" id="loader-body">
                <div class="loader-circle-2 ">
                  
                </div>
                <span className="gredient-text pt-3 pb-1">Loading...</span>
              </div>
              <form>
              <h2>Sign in</h2>
              <div className="position-relative ">
                
                <input 
                  name="username"
                  value={email}
                  autocomplete="off"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                  }}
                />
                <label className="email-lable" for="username">Email address</label>
                </div>
                <div className="position-relative">
                
                <input
                  name="password"
                  value={pwd}
                  required
                  autocomplete="off"
                  type={passwordType} id="password"
                  onChange={(e) => {
                    setPwd(e.target.value);
                    setError("");
                  }}
                />
                <label className="email-lable" for="password">Password</label>
                <div className="pass-show toggle-password" onClick={togglePassword}> { passwordType==="password"? <BsFillEyeFill /> :<BsFillEyeSlashFill /> }</div>
                </div>
                {/* <span className="error-message gredient-text">{error}</span> */}
                <div className="login-page-submit">
                  <Link to="/forgotpassword" replace={false} >
                    <button className="f-password">
                      Forgot password?
                    </button>
                  </Link>
                  <span onClick={loginapi} className="" type="submit" id="submit">
                    {/* <span className="gredient-text">Sign In</span> */}
                    <span className="quote"><span class="gredient-text">Sign In</span></span>
                  </span>
                </div>
              </form>
              <div className="centerline position-relative">
                <span className="centered-text">or</span>
              </div>
                <button className="Google">
                   <img src={images.Google} /> Google
                </button>
                
              <p>
                Don't have an account? <Link to="/signup" replace={false} className="gredient-text"> Sign up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
